import PropTypes from "prop-types"
import cx from "classnames"
import { useRouter } from "components/Router"
import { forwardRef } from "react"

const useHref = (hrefSpecs) => {
  const router = useRouter()
  const href =
    typeof hrefSpecs === "string"
      ? hrefSpecs
      : router.generate(hrefSpecs.route, hrefSpecs.params)
  return href
}

export const RawLink = forwardRef(function RawLink(props, ref) {
  const { external = false, target, href: hrefProps, ...rest } = props
  const href = useHref(hrefProps)

  return (
    <a
      rel={external ? "noopener noreferrer" : undefined}
      {...rest}
      target={external ? "_blank" : target}
      href={href}
      ref={ref}
    />
  )
})

export function Link(props) {
  const { className, external = false, ...rest } = props

  return (
    <RawLink
      className={cx(
        "text-primary-dark",
        "underline",
        "hover:text-primary-darker",
        "focus:text-primary-darker",
        className,
      )}
      external={external}
      {...rest}
    />
  )
}

export const hrefPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({ route: PropTypes.string, params: PropTypes.object }),
])

Link.propTypes = {
  href: hrefPropType.isRequired,
  external: PropTypes.bool,
  target: PropTypes.string,
  className: PropTypes.string,
}

RawLink.propTypes = Link.propTypes
