import * as React from "react"
import { Text } from "components/Text"
import { RawLink } from "components/Link"
import { Section } from "components/Section"
import { Wrapper } from "components/Wrapper"
import { MenuButton, Menu, MenuList } from "components/Menu"
import { MenuItem } from "components/MenuItem"
import { Separator } from "components/Separator"
import { useTranslator } from "components/Translator"
import { CaretDown } from "components/Icon/CaretDown"
import cx from "classnames"
import { DynamicIcon } from "components/Icon/DynamicIcon"
import { useZendesk } from "components/ZendeskWidget"

export const Footer = ({ footerNav }) => {
  const translator = useTranslator()
  const zendesk = useZendesk()

  const languages = [
    { locale: "fr", label: "Français" },
    { locale: "en", label: "English" },
  ]

  return (
    <div className="bg-primary-darker">
      <Section theme="primary">
        <Wrapper>
          <Menu
            trigger={
              <MenuButton
                label={translator.trans(
                  "Footer.switchLang.title",
                  null,
                  "components",
                )}
                endAdornment={<CaretDown className="w-4" />}
              />
            }
            list={
              <MenuList title={""}>
                {languages.map((language) => (
                  <MenuItem
                    key={language.locale}
                    href={`/${language.locale}`}
                    label={language.label}
                    selected={translator.locale === language.locale}
                  />
                ))}
              </MenuList>
            }
          />
        </Wrapper>
      </Section>
      <Wrapper>
        <div className="lg:grid lg:grid-cols-5 lg:gap-6">
          {footerNav.children.map((section) => {
            return (
              <React.Fragment key={section.name}>
                <Section theme="primary">
                  <Section.Title>{section.name}</Section.Title>
                  <div
                    className={cx("flex", {
                      "flex-col space-y-2": section.extras.layout === "block",
                      "flex-row space-x-2 items-center":
                        section.extras.layout === "inline",
                    })}
                  >
                    {section.children.map((content) => {
                      return content.uri === "__contact__" ? (
                        <Text
                          onClick={zendesk.activate}
                          variant="body2"
                          className="text-primary-lighter text-left"
                          tag="button"
                          disabled={!zendesk.isAvailable}
                          key="__contact__"
                        >
                          {content.name}
                        </Text>
                      ) : (
                        <RawLink
                          href={content.uri}
                          key={content.name}
                          {...(Array.isArray(content.attributes)
                            ? {}
                            : content.attributes)}
                          title={content.extras.icon ? content.name : undefined}
                        >
                          <Text
                            variant="body2"
                            className={
                              "text-primary-lighter " +
                              (content.attributes.className
                                ? content.attributes.className
                                : "")
                            }
                          >
                            {content.extras.icon ? (
                              <DynamicIcon
                                name={content.extras.icon}
                                className="w-8 text-white"
                              />
                            ) : (
                              content.name
                            )}
                          </Text>
                        </RawLink>
                      )
                    })}
                  </div>
                </Section>
                <div className="lg:hidden">
                  <Separator color="primary" />
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </Wrapper>
    </div>
  )
}
