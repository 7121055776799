import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react"
import { useRouter } from "components/Router"
import { client } from "api/client"
import { useTranslator } from "components/Translator"
import { Alerter } from "components/Alert"
import * as OrderDetailType from "enums/OrderDetailType"
import { useConnectedUser } from "components/ConnectedUserProvider"
import pick from "lodash/pick"

const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const router = useRouter()
  const translator = useTranslator()
  const connectedUser = useConnectedUser()
  const [state, dispatch] = useReducer(reducer, {
    value: null,
    error: null,
    status: "idle",
  })

  const fetchCart = useCallback(async () => {
    dispatch({ type: actionTypes.fetchCart })

    const url = router.generate("app_user_order_cart_index")

    try {
      const response = await client.get(url)

      dispatch({ type: actionTypes.success, payload: response.data.data.order })
    } catch (err) {
      console.error("Error while fetching cart", err)
      dispatch({ type: actionTypes.error, payload: err })
    }
  }, [router])

  useEffect(() => {
    if (connectedUser) {
      fetchCart()
    }
  }, [fetchCart, connectedUser])

  const addProduct = useCallback(
    async (product) => {
      dispatch({ type: actionTypes.addProduct })
      const url = router.generate("app_user_order_cart_product_add", product)

      try {
        const response = await client.post(url)
        dispatch({
          type: actionTypes.success,
          payload: response.data.data.order,
        })

        if (response.data.message) {
          Alerter.success(response.data.message, {
            actions: [
              {
                label: translator.trans(
                  "club.product.show.addToCartModal.successCTA",
                  null,
                  "pages",
                ),
                href: router.generate("app_user_order_cart_index"),
              },
            ],
          })
        }
      } catch (err) {
        if (err.response) {
          if (err.response.data.message) {
            Alerter.error(err.response.data.message)
          }
        } else {
          console.error("Error while adding product to cart", err)
        }

        dispatch({ type: actionTypes.error, payload: err })
      }
    },
    [router, translator],
  )

  const deleteLine = useCallback(
    async (line) => {
      dispatch({ type: actionTypes.deleteLine })

      const route = getDeleteRouteForOrderDetailType(line.type)
      const params = getDeleteRouteParams(line)
      const url = router.generate(route, params)

      try {
        const response = await client.delete(url)

        dispatch({
          type: actionTypes.success,
          payload: response.data.data.order,
        })

        if (response.data.message) {
          Alerter.success(response.data.message)
        }

        return response.data.data.order
      } catch (err) {
        if (err.response) {
          if (err.response.data.message) {
            Alerter.error(err.response.data.message)
          }
        } else {
          console.error("Error while deleting cart line", err)
        }

        dispatch({ type: actionTypes.error, payload: err })
      }
    },
    [router],
  )

  const contextValue = useMemo(
    () => ({
      ...state,
      addProduct,
      deleteLine,
    }),
    [addProduct, state, deleteLine],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.fetchCart:
      return {
        ...state,
        status: "loading",
      }

    case actionTypes.success:
      return {
        ...state,
        status: "success",
        value: action.payload,
      }

    case actionTypes.error:
      return {
        ...state,
        status: "error",
        error: action.payload,
      }

    case actionTypes.addProduct:
      return {
        ...state,
        status: "loading",
      }

    case actionTypes.deleteLine:
      return {
        ...state,
        status: "loading",
      }

    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

const actionTypes = {
  fetchCart: "FETCH_CART",
  success: "SUCCESS",
  error: "ERROR",
  addProduct: "ADD_PRODUCT",
  deleteLine: "DELETE_LINE",
}

export const useCart = () => {
  const context = useContext(CartContext)

  if (!context) {
    throw new Error("useCart should be used in a CartProvider")
  }

  return context
}

const getDeleteRouteForOrderDetailType = (orderDetailType) => {
  switch (orderDetailType) {
    case OrderDetailType.GIFT:
      return "app_user_order_cart_gift_remove"

    case OrderDetailType.PLAN:
      return "app_user_order_cart_plan_remove"

    case OrderDetailType.PRODUCT:
      return "app_user_order_cart_product_remove"
  }
}

const getDeleteRouteParams = (orderDetail) => {
  const params = pick(orderDetail.params, [
    "productPriceId",
    "productVariantId",
    "giftId",
    "planId",
    "planPriceId",
  ])

  return params
}
