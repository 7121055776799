import PropTypes from "prop-types"
import cx from "classnames"
import * as styles from "components/Spinner/style.css?module"

export function Spinner(props) {
  const { size = 32, className, ...rest } = props
  const borderWidth = Math.max(size / 10, 3)

  return (
    <div
      className={cx(styles.Spinner, className)}
      style={{
        width: size,
        height: size,
        "--border-width": borderWidth + "px",
      }}
      {...rest}
    />
  )
}

Spinner.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
}
