import * as React from "react"
import { useBreakpoints } from "hooks/useBreakpoints"

const ZendeskContext = React.createContext()

export const ZendeskProvider = ({ zendeskKey, defaultVisible, children }) => {
  const [isAvailable, setIsAvailable] = React.useState(false)
  const breakpoints = useBreakpoints()

  const activate = () => {
    if (!isAvailable) {
      console.warn(
        "Zendesk widget doesn't seem to be loaded. Impossible to open the widget. Check that you rendered a `ZendeskWidget` component and that you passed a valid `zendeskKey` prop to it.",
      )
      return
    }

    window.zE.activate()
  }

  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`
    script.id = "ze-snippet"

    /**
     * Hide the zendesk chat widget on load.
     * The user can still activate it by clicking on the help button in the header
     * See https://support.zendesk.com/hc/en-us/articles/360050522773-How-can-I-hide-the-widget-on-page-load-and-still-show-a-triggered-message-
     */
    script.onload = () => {
      setIsAvailable(true)
    }

    document.body.append(script)

    return () => {
      script.remove()
    }
  }, [zendeskKey])

  React.useEffect(() => {
    if (!isAvailable) {
      return
    }

    if (breakpoints.lg) {
      if (defaultVisible) {
        window.zE("webWidget", "show")
      } else {
        window.zE("webWidget", "hide")
      }
    } else {
      window.zE("webWidget", "hide")
    }
  }, [breakpoints.lg, isAvailable, defaultVisible])

  return (
    <ZendeskContext.Provider value={{ isAvailable, activate }}>
      {children}
    </ZendeskContext.Provider>
  )
}

export const useZendesk = () => {
  const context = React.useContext(ZendeskContext)

  if (!context) {
    throw new Error("useZendesk should be used in a ZendeskProvider")
  }

  return context
}
