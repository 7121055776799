import * as React from "react"
import PropTypes from "prop-types"
import { useBreakpoints } from "hooks/useBreakpoints"
import { Modal, ModalContent } from "components/Modal"
import cx from "classnames"
import { usePopper } from "react-popper"
import maxSize from "popper-max-size-modifier"
import useMergedRef from "@react-hook/merged-ref"

export const MenuList = React.forwardRef(function MenuList(
  {
    className,
    title,
    children,
    onRequestClose,
    isOpen,
    referenceElement,
    placement,
    closeIcon,
  },
  ref,
) {
  const { lg } = useBreakpoints()

  return lg ? (
    <FloatingMenuList
      className={className}
      isOpen={isOpen}
      referenceElement={referenceElement}
      ref={ref}
      placement={placement}
    >
      {children}
    </FloatingMenuList>
  ) : (
    <ModalMenuList
      isOpen={isOpen}
      title={title}
      onRequestClose={onRequestClose}
      closeIcon={closeIcon}
    >
      {children}
    </ModalMenuList>
  )
})

MenuList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  onRequestClose: PropTypes.func,
  closeIcon: PropTypes.node,
}

const ModalMenuList = ({
  children,
  onRequestClose,
  isOpen,
  title,
  closeIcon,
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      title={title}
      onRequestClose={onRequestClose}
      closeIcon={closeIcon}
    >
      <ModalContent wrapped={false}>{children}</ModalContent>
    </Modal>
  )
}

export const FloatingMenuList = React.forwardRef(function FloatingMenuList(
  {
    children,
    isOpen,
    referenceElement,
    placement = "bottom-start",
    className,
    ...props
  },
  ref,
) {
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      maxSize,
      applyMaxSize,
      { name: "offset", options: { offset: [0, 10] } },
    ],
  })

  const mergedRefs = useMergedRef(setPopperElement, ref)

  return (
    <div
      className={cx(
        "shadow-2 bg-white rounded border border-grey-medium z-20 overflow-y-auto",
        className,
      )}
      style={{ ...styles.popper, visibility: isOpen ? "visible" : "hidden" }}
      {...attributes.popper}
      ref={mergedRefs}
      {...props}
    >
      {children}
    </div>
  )
})

const applyMaxSize = {
  name: "applyMaxSize",
  enabled: true,
  phase: "beforeWrite",
  requires: ["maxSize"],
  fn({ state }) {
    const { height } = state.modifiersData.maxSize

    state.styles.popper = {
      ...state.styles.popper,
      maxHeight: `${height - 20}px`,
    }
  },
}
