import PropTypes from "prop-types"
import cx from "classnames"

export function Separator({ color = "default", vertical = false }) {
  return (
    <div
      className={cx("flex flex-row items-center justify-between ", {
        "border-grey-light": color === "default",
        "border-primary-dark": color === "primary",
        "border-secondary-dark": color === "secondary",
        "border-l": vertical,
        "border-t": !vertical,
      })}
    />
  )
}

Separator.propTypes = {
  color: PropTypes.oneOf(["default", "primary", "secondary", "current"]),
  vertical: PropTypes.bool,
}

export const WithSeparators = ({ children: childrenProps }) => {
  const children = childrenProps.reduce((children, element) => {
    if (children.length === 0) {
      return [element]
    }

    return children.concat(
      <Separator key={`${element.key}-separator`} />,
      element,
    )
  }, [])

  return children
}
