import { Text } from "components/Text"
import { Button } from "components/Button"
import { useTranslator } from "components/Translator"
import { useRouter } from "components/Router"
import { useZendesk } from "components/ZendeskWidget"
import errorBackground from "./background-error.jpg"
import { PressSection } from "components/PressSection"
import { useBreakpoints } from "hooks/useBreakpoints"
import { User } from "components/Icon/User"
import { useEffect, useCallback } from "react"

export function ErrorFallback(props) {
  const { errorProps, error } = props
  const translator = useTranslator()
  const router = useRouter()
  const zendesk = useZendesk()
  const { md } = useBreakpoints()

  const accountId = getAccountId()

  const reportError = useCallback(async () => {
    const url = router.generate("app_api_dump_js")
    const dataForm = new FormData()

    dataForm.append("message", error.message)
    dataForm.append("url", window.location.toString())
    dataForm.append("stack", error.stack)

    const response = await fetch(url, {
      method: "post",
      headers: { "X-Requested-With": "XMLHttpRequest" },
      body: dataForm,
    })

    if (response.ok) {
      console.info("Error has been reported")
    } else {
      console.warn(
        `There was an error when trying to report the error: ${response.statusText}`,
      )
    }
  }, [router, error])

  useEffect(() => {
    if (error && process.env.APP_ENV === "prod") {
      reportError()
    }
  }, [error, reportError])

  return (
    <div>
      <div
        className="bg-cover bg-center"
        style={{
          backgroundImage: `url(${errorBackground})`,
        }}
      >
        <div className="space-y-24 py-16">
          <div className="space-y-2 text-center items-center justify-center">
            <Text className="text-white" variant="headline1">
              {translator.trans("ErrorFallback.title", null, "components")}
            </Text>
            <Text className="text-white" variant="body1">
              {translator.trans("ErrorFallback.subtitle", null, "components")}
            </Text>
            {errorProps ? (
              <div className="space-y-4">
                <Text className="text-white" variant="body1">
                  ({errorProps.code} {errorProps.message})
                </Text>
                <div className="flex flex-row justify-center items-center">
                  <iframe
                    width={md ? "600" : "300"}
                    height={md ? "400" : "200"}
                    className="video"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={errorProps.video}
                  ></iframe>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col justify-center lg:flex-row lg:space-x-6 lg:space-y-0 space-y-2 px-5">
            <Button color="primary" href={{ route: "app_home_index" }}>
              {translator.trans(
                "ErrorFallback.redirectButton",
                null,
                "components",
              )}
            </Button>
            {accountId ? (
              <Button
                variant="outline"
                color="secondary"
                className="bg-white"
                iconLeft={<User className="w-4" />}
                href={{
                  route: "app_account_profile_show",
                  params: { userId: accountId },
                }}
              >
                {translator.trans(
                  "ErrorFallback.showProfile",
                  null,
                  "components",
                )}
              </Button>
            ) : null}
            <Button onClick={zendesk.activate} color="secondary">
              {translator.trans("ErrorFallback.helpButton", null, "components")}
            </Button>
          </div>
        </div>
      </div>
      {<PressSection />}
    </div>
  )
}

const getAccountId = () => {
  const currentUrl = window.location.href
  const accountIdRegex = /\/account\/(\d+)\//
  const result = currentUrl.match(accountIdRegex)
  const accountId = result ? result[1] : null

  return accountId
}
