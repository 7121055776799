import PropTypes from "prop-types"
import cx from "classnames"
import { Text } from "components/Text"

export const Alert = (props) => {
  const {
    severity,
    message,
    startAdornment,
    endAdornment,
    className,
    ...rest
  } = props

  const classNames = cx(
    "rounded",
    "justify-center",
    "items-center",
    "space-x-2",
    "focus:outline-none",
    "p-4",
    "rounded-md",
    "w-full",
    className,
    {
      "bg-success-lighter": severity == "success",
      "bg-error-lighter": severity == "error",
      "bg-secondary-lighter": severity == "warning",
      "bg-white text-default": severity == "notice",
      "text-dark": severity !== "notice",
    },
  )

  const AdornmentClassname = cx("shrink-0 grow-0")

  return (
    <div
      className={cx(
        "flex flex-col space-y-1 lg:flex-row lg:space-y-0 lg:space-x-2",
        classNames,
      )}
      {...rest}
    >
      {startAdornment ? (
        <div className={AdornmentClassname}>{startAdornment}</div>
      ) : null}
      {message ? (
        <div className="grow">
          <Text variant="body1">{message}</Text>
        </div>
      ) : null}
      {endAdornment ? (
        <div className={AdornmentClassname}>{endAdornment}</div>
      ) : null}
    </div>
  )
}

Alert.propTypes = {
  severity: PropTypes.oneOf(["notice", "success", "error", "warning"])
    .isRequired,
  message: PropTypes.node.isRequired,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  className: PropTypes.string,
}
