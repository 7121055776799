import PropTypes from "prop-types"
import cx from "classnames"
import { RawLink, hrefPropType } from "components/Link"
import omit from "lodash/omit"
import { Spinner } from "components/Spinner"
import { Text } from "components/Text"
import { forwardRef } from "react"

export const Button = forwardRef(function Button(props, ref) {
  const { iconLeft, iconRight, href, ...rest } = props
  const tag = href ? RawLink : undefined

  return (
    <ButtonBase
      iconLeft={iconLeft}
      iconRight={iconRight}
      tag={tag}
      href={href}
      {...rest}
      ref={ref}
    />
  )
})

Button.propTypes = {
  variant: PropTypes.oneOf(["default", "outline", "text"]),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary", "danger", "success"]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  busy: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  href: hrefPropType,
}

export const ButtonBase = forwardRef(function ButtonBase(props, ref) {
  const {
    disabled: disabledProps = false,
    className,
    color = "primary",
    tag: Tag = "button",
    fullWidth = false,
    busy = false,
    variant = "default",
    children,
    iconLeft,
    iconRight,
    size = "medium",
    ...rest
  } = props

  const disabled = busy || disabledProps

  const classNames = cx(
    "rounded",
    "justify-center",
    "items-center",
    "font-medium",
    "leading-5",
    "text-center",
    "flex",
    "flex-row",
    "focus:outline-none",
    "appearance-none",
    className,
    {
      "px-6": size === "medium" && children,
      "px-4": size === "medium" && !children,
      "px-2": size === "small" && children,
      "px-1": size === "small" && !children,

      "py-2 min-h-12 space-x-2": size === "medium",

      "py-1 min-h-7 space-x-1": size === "small",

      "focus:ring-4": ["default", "outline"].includes(variant) && !disabled,

      "active:ring-2": !disabled,

      "bg-secondary-default focus:ring-secondary-lighter hover:bg-secondary-dark active:ring-secondary-dark text-secondary-contrast":
        variant === "default" && color == "primary" && !disabled,
      "bg-secondary-light text-light":
        variant === "default" && color == "primary" && disabled,

      "border border-grey-medium hover:border-secondary-default text-secondary-dark active:ring-secondary-default focus:ring-secondary-lighter":
        variant === "outline" && color == "primary" && !disabled,
      "border border-grey-light text-secondary-light":
        variant === "outline" && color == "primary" && disabled,

      "text-secondary-dark hover:text-secondary-darker focus:bg-secondary-lighter active:ring-secondary-light":
        variant === "text" && color == "primary" && !disabled,
      "text-secondary-light":
        variant === "text" && color == "primary" && disabled,

      "bg-primary-dark hover:bg-primary-darker text-primary-contrast focus:ring-primary-lighter active:ring-primary-darker":
        variant === "default" && color == "secondary" && !disabled,
      "bg-primary-light text-grey-white":
        variant === "default" && color == "secondary" && disabled,

      "border border-grey-medium hover:border-primary-dark text-primary-dark focus:ring-primary-lighter active:ring-primary-darker":
        variant === "outline" && color == "secondary" && !disabled,
      "border border-grey-light text-primary-light":
        variant === "outline" && color == "secondary" && disabled,

      "hover:text-primary-darker text-primary-dark focus:bg-primary-lighter active:ring-primary-light":
        variant === "text" && color == "secondary" && !disabled,
      "text-primary-light":
        variant === "text" && color == "secondary" && disabled,

      "bg-error-light hover:bg-error-default text-error-default hover:text-white focus:ring-error-lighter active:ring-error-default":
        variant === "default" && color === "danger" && !disabled,
      "bg-error-lighter text-error-light":
        variant === "default" && color === "danger" && disabled,

      "border border-grey-medium hover:border-error-default text-error-default focus:ring-error-lighter active:ring-error-default":
        variant === "outline" && color === "danger" && !disabled,
      "border border-grey-light text-error-light":
        variant === "outline" && color === "danger" && disabled,

      "text-error-default hover:text-dark focus:bg-error-lighter active:ring-error-light":
        variant === "text" && color === "danger" && !disabled,
      "text-error-light": variant === "text" && color === "danger" && disabled,

      "bg-success-light hover:bg-success-default text-success-default hover:text-white focus:ring-success-lighter active:ring-success-default":
        variant === "default" && color === "success" && !disabled,
      "bg-success-lighter text-success-light":
        variant === "default" && color === "success" && disabled,

      "border border-grey-medium hover:border-success-default text-success-default focus:ring-success-lighter active:ring-success-default":
        variant === "outline" && color === "success" && !disabled,
      "border border-grey-light text-success-light":
        variant === "outline" && color === "success" && disabled,

      "text-success-default hover:text-dark focus:bg-success-lighter active:ring-success-light":
        variant === "text" && color === "success" && !disabled,
      "text-success-light":
        variant === "text" && color === "success" && disabled,

      "flex w-full": fullWidth,
      "inline-flex": !fullWidth,
      "cursor-default": disabled,
    },
  )

  const textVariant =
    size === "medium" ? "button" : size === "small" ? "caption" : null

  return (
    <Text
      variant={textVariant}
      tag={Tag}
      className={classNames}
      disabled={disabled}
      {...rest}
      ref={ref}
    >
      {iconLeft ? <span className="shrink-0">{iconLeft}</span> : null}
      {children ? <span>{children}</span> : null}
      {iconRight ? <span className="shrink-0">{iconRight}</span> : null}
      {busy ? (
        <span className="shrink-0">
          <Spinner size={16} />
        </span>
      ) : null}
    </Text>
  )
})

ButtonBase.propTypes = {
  ...omit(Button.propTypes, ["href"]),
  tag: PropTypes.elementType,
}
