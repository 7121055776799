import PropTypes from "prop-types"
import { Avatar } from "components/Avatar"
import { Text } from "components/Text"
import * as Civility from "enums/Civility"
import cx from "classnames"
import { getImageSrc } from "components/Image"
import { UserCircle } from "components/Icon/UserCircle"

export const UserAvatar = ({ user, size, alt, showName = false }) => {
  const sizeToThumb = {
    xsmall: "w40",
    small: "w75",
    medium: "w150",
    large: "w400",
  }

  const sizeToFallback = {
    xsmall: "w-8 h-8",
    small: "w-12 h-12",
    medium: "w-20 h-20",
    large: "w-40 h-40",
  }

  const fallbackSize = sizeToFallback[size]
  const thumb = sizeToThumb[size]

  return (
    <div className="flex flex-col items-center space-y-1">
      <Avatar
        className={cx({
          "text-pink": user.civility?.enum_value === Civility.WOMAN,
        })}
        src={user.imageFile ? getImageSrc(user.imageFile, thumb) : null}
        alt={alt ?? user.fullName}
        size={size}
        fallback={<UserCircle className={fallbackSize} />}
      />
      {showName ? (
        <Text variant="caption" className="text-center">
          {user.fullName}
        </Text>
      ) : null}
    </div>
  )
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.string,
  alt: PropTypes.string,
  showName: PropTypes.bool,
}
