import { App } from "components/App"
import * as flags from "utils/flags"
import { createRoot } from "react-dom/client"

if (process.env.NODE_ENV === "development") {
  window.__flags__ = flags
}

export function renderPage({
  component: Component,
  connectedUser = window.user,
  headerPrimaryNav = window.headerPrimaryNav,
  headerSecondaryNav = window.headerSecondaryNav,
  footerNav = window.footerNav,
  props = window.pageProps || {},
  rootElementId = "app",
  flashMessages = window.flashMessages,
  wrapper: Wrapper = App,
}) {
  const appElement = document.getElementById(rootElementId)
  const locale = document.documentElement.lang.split("-")[0]

  if (!appElement) {
    throw new Error(`Element with id="${rootElementId}" doesn't exist`)
  }

  window.__dateFnsLocale__ = locale

  const root = createRoot(appElement)

  root.render(
    <Wrapper
      component={Component}
      locale={locale}
      {...props}
      connectedUser={connectedUser}
      headerPrimaryNav={headerPrimaryNav}
      headerSecondaryNav={headerSecondaryNav}
      footerNav={footerNav}
      flashMessages={flashMessages}
    />,
  )
}
