import PropTypes from "prop-types"
import { useBreakpoints } from "hooks/useBreakpoints"
import { LogoFull } from "components/Logo"
import { AngleDown } from "components/Icon/AngleDown"
import { Search } from "components/Icon/Search"
import { Bars } from "components/Icon/Bars"
import { UserAvatar } from "domains/user/UserAvatar"
import { RawLink } from "components/Link"
import { MenuButton } from "components/Menu"
import { Button } from "components/Button"
import { Wrapper } from "components/Wrapper"
import { useTranslator } from "components/Translator"
import { NotificationCounter } from "components/NotificationCounter"
import { KnpMenu } from "components/KnpMenu"
import { DynamicIcon } from "components/Icon/DynamicIcon"
import { ShoppingCart } from "components/Icon/ShoppingCart"
import { useConnectedUser } from "components/ConnectedUserProvider"
import { QuestionCircle } from "components/Icon/QuestionCircle"
import { useCart } from "components/CartProvider"
import { useZendesk } from "components/ZendeskWidget"
import { Text } from "components/Text"

export function Header(props) {
  const { primaryNav, secondaryNav } = props
  const connectedUser = useConnectedUser()

  return (
    <div className="w-full sticky top-0 shadow-3 z-30" id="header">
      <PrimaryNav nav={primaryNav} />

      {connectedUser && secondaryNav ? (
        <SecondaryNav nav={secondaryNav} />
      ) : null}
    </div>
  )
}

const navItemShape = {
  label: PropTypes.string,
  uri: PropTypes.string,
  extras: PropTypes.shape({
    icon: PropTypes.string,
    notifications: PropTypes.number,
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(["link", "delimiter"]),
  }),
}

const navShape = {
  children: PropTypes.arrayOf(PropTypes.shape(navItemShape)),
}

const userShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  imageFile: PropTypes.shape({
    url: PropTypes.string,
  }),
})

navItemShape.children = PropTypes.arrayOf(PropTypes.shape(navItemShape))

Header.propTypes = {
  className: PropTypes.string,
  primaryNav: PropTypes.shape(navShape),
  secondaryNav: PropTypes.shape(navShape),
  user: userShape,
}

const CartButton = () => {
  const cart = useCart()

  const button = (
    <MenuButton
      illustration={<ShoppingCart className="w-4" />}
      color="light"
      href={{ route: "app_user_order_cart_index" }}
      className="border border-grey-light"
    />
  )

  if (cart.status !== "success") {
    return button
  }

  return (
    <NotificationCounter
      count={cart.value ? cart.value.finalPriceDetails.length : 0}
      showZero
    >
      {button}
    </NotificationCounter>
  )
}

const PrimaryNav = ({ nav }) => {
  const { lg } = useBreakpoints()
  const translator = useTranslator()
  const connectedUser = useConnectedUser()
  const zendesk = useZendesk()

  return (
    <div className={"bg-white"}>
      <Wrapper size="large">
        <div className="flex flex-row items-center py-4 justify-between w-full">
          <div className="flex flex-row space-x-4 items-center">
            {!lg ? (
              <KnpMenu
                key={nav.name}
                nav={nav}
                trigger={
                  <MenuButton
                    illustration={<Bars className="w-4" />}
                    color="light"
                  />
                }
              />
            ) : null}

            <div className="flex flex-row space-x-2 items-center">
              <RawLink href={nav.children[0].uri}>
                <LogoFull width="108" height="48" />
              </RawLink>
              <Text
                variant="body2"
                className="text-primary-dark font-bold hidden sm:block"
              >
                {translator.trans("Header.baseline", null, "components")}
              </Text>
            </div>
          </div>

          <div className="flex flex-row items-center space-x-8">
            {lg ? (
              <div className="flex flex-row space-x-1 items-center">
                {nav.children.map((menuElement, index) => {
                  if (index === 0) {
                    return null
                  }

                  return (
                    <KnpMenu
                      key={menuElement.name}
                      nav={menuElement}
                      trigger={
                        <MenuButton
                          label={menuElement.name}
                          color={menuElement.isCurrent ? "default" : "light"}
                          endAdornment={
                            lg && menuElement.children.length > 0 ? (
                              <AngleDown className="w-2.5" />
                            ) : null
                          }
                        />
                      }
                    />
                  )
                })}
              </div>
            ) : null}

            <div className="flex flex-row items-center space-x-1">
              {connectedUser ? (
                <CartButton />
              ) : (
                <div className={"flex flex-row space-x-2 items-center"}>
                  <Button
                    href={{ route: "app_user_security_login" }}
                    color={lg ? "secondary" : "primary"}
                    variant={lg ? "text" : "default"}
                    size={lg ? "medium" : "small"}
                  >
                    {translator.trans("Header.login", {}, "components")}
                  </Button>
                  {lg ? (
                    <Button
                      href={{ route: "app_user_security_register" }}
                      variant="default"
                      color="primary"
                    >
                      {translator.trans("Header.register", {}, "components")}
                    </Button>
                  ) : null}
                </div>
              )}

              {!connectedUser ? (
                <MenuButton
                  illustration={<Search className="w-4" />}
                  color="light"
                  href={{ route: "app_search_advert" }}
                />
              ) : null}

              <MenuButton
                illustration={<QuestionCircle className="w-5" />}
                color="default"
                disabled={!zendesk.isAvailable}
                onClick={zendesk.activate}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

const SecondaryNav = ({ nav }) => {
  const { lg } = useBreakpoints()
  const connectedUser = useConnectedUser()

  const items = nav.children.map((menuElement, index) => {
    const trigger = (
      <MenuButton
        color={
          [2, 3, 4].includes(index) || (index === 3 && lg) ? "light" : "default"
        }
        label={
          lg && menuElement.extras.variant !== "compact"
            ? menuElement.name
            : null
        }
        endAdornment={
          lg &&
          menuElement.children.length > 0 &&
          menuElement.extras.variant !== "compact" ? (
            <AngleDown className="w-2.5" />
          ) : null
        }
        illustration={
          menuElement.extras.icon ? (
            menuElement.extras.icon === "__user_avatar__" ? (
              <UserAvatar user={connectedUser} size="small" />
            ) : (
              <DynamicIcon name={menuElement.extras.icon} className="w-4" />
            )
          ) : null
        }
        href={menuElement.children.length === 0 ? menuElement.uri : undefined}
      />
    )

    return (
      <NotificationCounter
        key={menuElement.name}
        count={
          menuElement.extras.counter ? Number(menuElement.extras.counter) : 0
        }
      >
        <KnpMenu key={menuElement.name} nav={menuElement} trigger={trigger} />
      </NotificationCounter>
    )
  })

  return (
    <div className="bg-grey-light py-3">
      <Wrapper>
        <div className={"hidden lg:block"}>
          <SecondaryNavDesktop items={items} />
        </div>

        <div
          className={"grid justify-items-center lg:hidden"}
          style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
        >
          {items}
        </div>
      </Wrapper>
    </div>
  )
}

const SecondaryNavDesktop = ({ items }) => {
  const [publishItem, searchItem, ...otherItems] = items
  return (
    <div className={"flex justify-between"}>
      <div className={"flex gap-4"}>
        {publishItem}
        {searchItem}
      </div>

      <div className={"flex gap-4"}>{otherItems}</div>
    </div>
  )
}
